<script>
export default {
  metaInfo: {
    title: "Spreadsheets",
  }
};
</script>

<script setup>
import DynamicSpreadsheetsView from '@/components/dynamic/SpreadsheetsView.vue';
</script>

<template>
  <DynamicSpreadsheetsView/>
</template>

<style scoped>

</style>